#loader {
    position: absolute;
    top: 30%;
    left: 47%;
}

#loader h1 {
    text-align: center;
}

#loader svg {
    display: block;
    margin: auto
}