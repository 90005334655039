main.dashboard {
    display: flex;
    justify-content: center;
    margin-top: -100vh;
}

.dashboard-links {
    position: absolute;
    width: 25rem;
    top: 30%;
    left: 42.5%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.dashboard-links a {
    color: #000;
    text-decoration: none;
    height: 8rem;
    width: 8rem;
    background-color: #bdbdbd;
    padding: 1rem;
    border-radius: 10px;
    line-height: 3rem;
    text-align: center;
    margin: 1rem 0;
}

.dashboard-links a:hover {
    color: #222;
    background-color: #cfcfcf;
}

.dashboard-links a .dashboard-link-icons {
    display: block;
}