li.user-card {
    margin: 0 3rem;
    border-radius: 5px;
    box-shadow: 0 0 10px #dadada;
    height: 20rem;
    padding: 1rem;
}

img.user-card-image {
    max-height: 70%;
    border-radius: 5px;
}

p.user-card-status {
    color: #8b8b8b;
}