#login {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70vh;
}

form#login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 17rem;
}
form#login-form section {
    display: flex;
}

#login-background {
    box-shadow: 0 0 10px #adadad;
    padding: 1rem 2.5rem;
    border-radius: 1em;
}

label.login-label {
    font-size: 1em;
    padding-top: 20px;
    padding-right: 20px;
}

form#login-form section input {
    font-size: 1.2em;
    padding: .2rem .5rem;
    padding-top: 1.2rem;
    border: none;
    outline: none;
    border-bottom: 2px solid #dadada;
    order: 2;
}

label.login-label::after {
    content: '';
    margin-top: 1.55rem;
    width: 14rem;
    display: inline-block;
    border-bottom: 2px solid #4eb5f1;
    transform: translateX(-100%);
    position: absolute;
    opacity: 0;
    transition: all .3s ease;
}

form#login-form section input:focus + label.login-label::after {
    transform: translateX(9%);
    opacity: 1;
}

form#login-form input[type=submit] {
    align-self: flex-start;
    background-color: #4eb5f1;
    border: none;
    border-radius: 20px;
    padding: .3em 1em;
    color: #fff;
    font-size: 1.2em;
    outline: none;
    border-radius: 0 0 10px #adadad;
    cursor: pointer;
}

.login-error {
    color: #D8000C;
    background-color: #FFD2D2;
    width: 100%;
    text-align: center;
    border-radius: 5px;
    padding: .1rem .5rem;
}