main.new-user {
    display: flex;
    justify-content: center;
    margin-top: -100vh;
    flex-direction: column;
    align-items: center;
}

.new-user-form {
    padding: 1.5rem 2rem;
    border-radius: 2em;
    box-shadow: 0 0 10px #bebebe;
    position: absolute;
    top: 20%;
}

fieldset.new-user-field {
    border: none;
    width: 100%;
    margin: 1rem 0;
}

fieldset.new-user-field input {
    font-size: 1.2em;
    border: none;
    border-bottom: 2px solid #adadad;
    float: right;
    width: 10rem;
    margin-right: 2rem;
    outline: none;
    z-index: 1;
}

select.new-user-permissions {
    border: 1px solid #adadad;
    border-radius: 5px;
    font-size: 1em;
    padding: .1rem .3rem;
    float: right;
    margin-right: 5rem;
    outline: none;
}

.new-user-field label::after {
    content: '';
    margin-top: 1.5rem;
    width: 11rem;
    display: inline-block;
    border-bottom: 2px solid #4eb5f1;
    transform: translateX(-100%);
    position: absolute;
    opacity: 0;
    transition: all .3s ease;
}

.new-user-field input:focus + label[for='new-user-email']::after {
    transform: translateX(30%);
}

.new-user-field input:focus + label::after {
    transform: translateX(14%);
    opacity: 1;
}

.new-user-field .new-user-submit {
    padding: 0.3em 1.2em;
    margin: 0 30%;
    border-radius: 2em;
    border: none;
    text-decoration: none;
    color: #FFFFFF;
    background-color: #4eb5f1;
    transition: all 0.2s;
    font-size: 1em;
    cursor: pointer;
}

.new-user-submit:hover {
    background-color:#4095c6;
}