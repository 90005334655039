ul.post-info {
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 80vw;
    justify-content: space-around;
    align-items: center;
    padding-top: 1rem;
    border-top: 2px solid #7a7a7a;
    flex-wrap: wrap;
}

ul.post-info-head {
    list-style: none;
    display: flex;
    flex-direction: row;
    width: 40vw;
    justify-content: space-around;
    align-items: center;
    padding-top: 1rem;
    margin-left: -1.5rem;
}

ul.post-info li {
    flex: 3;
}

ul.post-info img.inline-img {
    height: 3rem;
    width: 5rem;
}

#show-card {
    flex: 1 0 100%;
    width: 90vw;
}

#show-more-button {
    flex: 2;
    text-align: center;
}

#show-more-button:hover {
    color: #cfcfcf;
    cursor: pointer;
}

ul.post-info li.show-buttons {
    display:inline-block;
    padding: 0.3em 1.2em;
    margin: 0 0.3em 0.3em 0;
    height: 2rem;
    border-radius: 2em;
    border: none;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    color:#FFFFFF;
    text-align: center;
    transition: all 0.2s;
    cursor: pointer;
    flex: 1;
}

#edit-button {
    background-color: #fad348;
}

#edit-button a {
    color: #fff;
    text-decoration: none;
}

#edit-button:hover {
    background-color: #ffe37d;
}

#delete-button {
    background-color: #bd0000;
}

#delete-button:hover {
    background-color: #ff4a4a;
}