main.users {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -100vh;
}

ul.user-card-list {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    list-style: none;
    padding-left: 0;
    padding-bottom: 5rem;
    border-bottom: 2px solid #adadad;
}

button.new-user-button {
    display:inline-block;
    padding:0.3em 1.2em;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;
    border: none;
    box-sizing: border-box;
    text-decoration:none;
    font-weight:300;
    color:#FFFFFF;
    background-color:#4eb5f1;
    text-align:center;
    transition: all 0.2s;
    font-size: 2em;
    cursor: pointer;
}

button.new-user-button:hover {
    background-color:#4095c6;
}

#new-user-button-text {
    margin-right: .5rem;
}