nav.navbar {
    display: flex;
    background: transparent;
    color: #818181;
    background-color: #111;
}

nav#hiddenNav {
    display: none;
}

.navbar #brand {
    flex: 6;
    padding-left: 1rem;
    font-size: 1.5em;
    text-transform: uppercase;
    letter-spacing: .2em;
    margin: auto 0;
    cursor: pointer;
}

.navbar #brand:hover a {
    color: #a3a3a3;
}

.navbar #brand img{
    height: 70px;
    width: 100px;
}

nav.navbar div {
    flex: none;
}

.menu-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .5s ease-in-out;
    height: 80px;
    margin-right: 1rem;
}

.hamburger-menu {
    width: 50px;
    height: 6px;
    background-color: #818181;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}

.hamburger-menu::before, .hamburger-menu::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 6px;
    background-color: #818181;
    border-radius: 5px;
    transition: all .5s ease-in-out;
}

.hamburger-menu::before {
    transform: translateY(-16px);
}

.hamburger-menu::after {
    transform: translateY(16px);
}

.menu-btn.open .hamburger-menu {
    transform: translateX(-50px);
    background: transparent;
}

.menu-btn.menu-btn.open .hamburger-menu::before {
    transform: rotate(45deg) translate(30px, -30px);
}

.menu-btn.open .hamburger-menu::after {
    transform: rotate(-45deg) translate(30px, 30px);
}

.dropdown {
    position: absolute;
    top: 70px;
    width: 10rem;
    transform: translateX(-90%);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    background-color: #111;
    margin: 0 auto;
    border-radius: 5px;
    box-shadow: 0 0 10px #000;
    overflow: hidden;
    transition: height .5s ease;
}

.menu-item {
    display: flex;
    align-items: center;
    transition: background-color .5s;
    padding: 0.5rem;
    color: #818181;
    border-radius: 5px;
    cursor: pointer;
}

.menu-item:hover {
    color: #f1f1f1;
    background-color: #222;
}

.menu-item:hover span {
    background-color: #333;
}

span.menu-item > span {
    background-color: #222;
    padding: .5rem;
    padding-right: 0;
    width: 1.5rem;
    height: 1rem;
    border-radius: 50px;
    margin-right: .5rem;
}

span.menu-item span.right-icon {
    background-color: transparent;
}

.menu-primary-enter {
    position: absolute;
    transform: translateX(-110%);
}

.menu-primary-enter-active {
    transform: translateX(0%);
    transition: all .5s ease;
}

.menu-primary-exit {
    position: absolute;
}

.menu-primary-exit-active {
    transform: translateX(-110%);
    transition: all .5s ease;
}

.menu-secondary-enter {
    position: absolute;
    transform: translateX(110%);
}

.menu-secondary-enter-active {
    transform: translateX(0%);
    transition: all .5s ease;
}

.menu-secondary-exit {
    position: absolute;
}

.menu-secondary-exit-active {
    transform: translateX(110%);
    transition: all .5s ease;
}