.create-new-user h1 {
    text-align: center;
    margin-top: 2rem;
}

.create-new-user h3 {
    text-align: center;
    font-weight: 400;
}

.create-user-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 30%;
    left: 37%;
    box-shadow: 0 0 10px #adadad;
    border-radius: 2em;
    padding: 1rem 3rem 0rem;
}

.create-user-form .form-group {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}

#new-user-image {
    max-width: 12rem;    
    padding: .5rem 0 0 0;
    border-radius: 5px;
    margin: 0 auto;
}