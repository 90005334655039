main.edit {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -100vh;
}

.edit-post-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

fieldset.edit-post-field {
    border: none;
    display: flex;
    flex-direction: column;
}
#edit-post-content {
    width: 50rem;
}

#edit-post-title {
    border-radius: 5px;
    border: 2px solid #adadad;
    font-size: 2em;
}

.edit-form-button {
    display:inline-block;
    padding:0.3em 1.2em;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;
    border: none;
    box-sizing: border-box;
    text-decoration:none;
    font-weight:300;
    color:#FFFFFF;
    background-color:#4eb5f1;
    text-align:center;
    transition: all 0.2s;
    cursor: pointer;
}

.edit-form-button:hover {
    background-color:#4095c6;
}

/* input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 6px;
  left: 0;
  font-size: 15px;
  color: rgb(153,153,153);
} */

fieldset.edit-post-field label {
    font-size: 1.5em;
}

#edit-upload-image {
    height: 10rem;
    width: 15rem;
    padding: .5rem;
    margin: .5rem;
    box-shadow: 0 0 10px #adadad;
    border-radius: 5px;
}