section#show-post {
    transition: all 1s ease-in-out;
    overflow: hidden;
    box-shadow: inset 0 0 10px #969696;
}

section#show-post.hidden {
    height: 0;
}

section#show-post.shown {
    overflow-y: scroll;
    height: 35rem;
    padding: 2rem 5rem;
}

section#show-post article {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#show-time {
    align-self: flex-start;
    color: #888;
    text-transform: uppercase;
    letter-spacing: 1px;
}

#show-title {
    text-align: center;
}

#show-content {
    max-width: 70rem;
}

img#show-card-image {
    border-radius: 5px;
    height: 30rem;
    width: 50rem;
}