aside.sidebar {
    background-color: #111;
    width: 4rem;
    height: 100vh;
    display: inline-block;
    transition: width .5s ease-in-out;
    padding-top: 11px;
    position: relative;
    z-index: 1;
    position: sticky;
    top: 0;
}

aside#hiddenSidebar {
    display: none;
}

aside.sidebar:hover {
    width: 16rem;
}

nav.sidebar-list {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 25px;
    margin-top: 5rem;
}

nav a {
    text-decoration: none;
    color: #818181;
}

li.sidebar-list-item {
    list-style: none;
    width: 85%;
    padding: 1rem 1rem;
    padding-right: 0;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
}

.sidebar-text {
    margin-left: 2rem;
}

li.sidebar-list-item:hover {
    color: #f1f1f1;
    cursor: pointer;
}