main.new {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -100vh;
}

.new-post-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

fieldset.new-post-field {
    border: none;
    display: flex;
    flex-direction: column;
}

#new-post-title {
    border-radius: 5px;
    border: 2px solid #adadad;
    font-size: 2em;
}

#new-post-content {
    width: 50rem;
}

.new-form-button {
    display: inline-block;
    padding: 0.3em 1.2em;
    margin: 0 0.3em 0.3em 0;
    border-radius: 2em;
    border: none;
    box-sizing: border-box;
    text-decoration: none;
    font-weight: 300;
    color: #FFFFFF;
    background-color: #4eb5f1;
    text-align: center;
    transition: all 0.2s;
    cursor: pointer;
}

.new-form-button:hover {
    background-color:#4095c6;
}

@media all and (max-width:30em) {
    .new-form-button{
        display:block;
        margin:0.2em auto;
    }
}

input[type="file"]#new-post-image {
  margin: 1rem .5rem;
}

fieldset.new-post-field label {
    font-size: 1.5em;
}

#upload-image {
    height: 10rem;
    width: 15rem;
    padding: .5rem;
    margin: .5rem;
    box-shadow: 0 0 10px #adadad;
    border-radius: 5px;
}